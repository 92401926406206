import React from 'react';
import { Card, Row, Col, Image } from 'react-bootstrap';
import googleLogo from '../../img/icons-google.png';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconShare } from '../../img/sprite/svg/share.svg';

const SingleReview = ({
  id,
  author,
  rating,
  date,
  snippet,
  downloadReviewModal,
  googlePlaceId
}) => {
  const { t } = useTranslation();
  const firstLetter = author.charAt(0).toUpperCase();

  const getBackgroundColor = (name) => {
    const colors = [
      '#FF5722', // Deep Orange
      '#3F51B5', // Indigo
      '#4CAF50', // Green
      '#FFC107', // Amber
      '#9C27B0', // Purple
      '#03A9F4', // Light Blue
      '#E91E63', // Pink
      '#8BC34A' // Light Green
    ];
    const charCode = name.charCodeAt(0);
    return colors[charCode % colors.length];
  };

  const backgroundColor = getBackgroundColor(author);

  const handleDownload = () => {
    downloadReviewModal(
      author,
      rating,
      date,
      snippet,
      backgroundColor,
      firstLetter,
      googlePlaceId
    );
  };

  return (
    <Card className="review-card mb-4">
      <Card.Body>
        <Row className="align-items-center mb-1">
          <Col xs="auto" className="pr-0">
            <div
              className="circle text-white d-flex align-items-center justify-content-center"
              style={{ backgroundColor }}
            >
              {firstLetter}
            </div>
          </Col>
          <Col>
            <Card.Title className="mb-0">{author}</Card.Title>
          </Col>
        </Row>

        <Row className="mb-1">
          <Col>
            {[...Array(5)].map((_, index) => (
              <i
                key={index}
                className={`bi ${
                  index < rating
                    ? 'bi-star-fill text-warning'
                    : 'bi-star-fill text-secondary'
                }`}
              ></i>
            ))}
          </Col>
        </Row>

        <Row className="mb-1">
          <Col>
            <Card.Text>{snippet}</Card.Text>
          </Col>
        </Row>

        {date && (
          <Row className="mb-1 text-muted">
            <Col>{date}</Col>
          </Row>
        )}

        <Row className="align-items-center mb-1">
          <Col xs="auto">
            {t('ReviewsPage.importedFrom')}:{' '}
            <Image src={googleLogo} alt="Google logo" className="google-logo" />
          </Col>
        </Row>

        <Row>
          <Col>
            <button
              className="btn-responses btn-share"
              type="button"
              onClick={handleDownload}
            >
              <IconShare />
              {t('ReviewsPage.share')}
            </button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SingleReview;

SingleReview.propTypes = {
  id: PropTypes.number,
  author: PropTypes.string,
  rating: PropTypes.string,
  date: PropTypes.string,
  snippet: PropTypes.string,
  downloadReviewModal: PropTypes.func
};

SingleReview.defaultProps = {
  id: null,
  author: '',
  rating: null,
  date: null,
  snippet: '',
  downloadReviewModal: null
};
