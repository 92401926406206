import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import device from '../../img/app-device.png';

const StatisticStart = ({ noData }) => {
  const { t } = useTranslation();

  return (
    <div className="statistics-start">
      <div className="statistics-start__container">
        <img src={device} alt="" />
        <h2 className="statistics-start__title-first-line">
          {noData
            ? t('statisticPage.noDataForPeriod')
            : t('statisticPage.noActivePolls')}
        </h2>
        {noData && (
          <h2 className="statistics-start__title-second-line">
            {t('statisticPage.tryAnotherPeriod')}
          </h2>
        )}
      </div>
    </div>
  );
};

export default StatisticStart;

StatisticStart.propTypes = {
  noData: PropTypes.bool
};

StatisticStart.defaultProps = {
  noData: false
};
